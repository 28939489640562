import { createGlobalStyle } from 'styled-components'

import animations from './animations'
import RobotoLightWoff2 from 'src/assets/fonts/Roboto-Light.woff2'
import RobotoLightWoff from 'src/assets/fonts/Roboto-Light.woff'
// import RobotoItalicWoff2 from 'src/assets/fonts/Roboto-Italic.woff2'
// import RobotoItalicWoff from 'src/assets/fonts/Roboto-Italic.woff'

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html, body, p, a, button {
    font-family: var(--typography-font-default),'Roboto', Arial, sans-serif;
  }

  p {
    font-size: 14px;
  }

  #root {
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    --typography-font-default: 'Roboto';
  }
  
  ${animations}

    @font-face {
    font-family: 'Roboto';
    src: url(${RobotoLightWoff2}) format('woff2'),
      url(${RobotoLightWoff}) format('woff');
       font-weight: 200;
    font-style: normal;
}

  @font-face {
    font-family: 'Roboto';
    src: url(${RobotoLightWoff2}) format('woff2'),
      url(${RobotoLightWoff}) format('woff');
   
    font-style: normal;
}
  
  
   @font-face {
    font-family: 'Roboto';
    src: url(${RobotoLightWoff2}) format('woff2'),
      url(${RobotoLightWoff}) format('woff');
      font-style: normal;
    
}
   @font-face {
    font-family: 'Roboto';
    src: url(${RobotoLightWoff2}) format('woff2'),
      url(${RobotoLightWoff}) format('woff');
    font-style: normal;
}

`

export default GlobalStyle
