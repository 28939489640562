export const PRODUCT_TYPE = {
  nonFuel: 'N',
  fuel: 'F'
}

export const PRODUCT_MAP = {
  300: 'lubricants',
  10001: 'diesel_products',
  10002: 'ultimate_diesel',
  10004: 'hvo',
  10003: 'biodiesel',
  20001: 'ms_unleaded',
  20002: 'ms_leaded',
  20003: 'lpg_gas',
  20004: 'two_stroke',
  20005: 'other_ms_products',
  20006: 'ethanol',
  20007: 'electricity',
  20008: 'ultimate_unleaded',
  40001: 'tyres',
  40002: 'batteries',
  40003: 'spare_parts',
  40006: 'adblue',
  60001: 'food',
  60002: 'sweets',
  60003: 'beverages',
  60004: 'maps',
  60005: 'newspapers_books',
  60006: 'tobacco',
  60007: 'restaurant',
  60008: 'vps_site_gift_card',
  60009: 'health_and_beauty_care',
  60010: 'other_goods',
  80001: 'car_wash',
  80002: 'truck_wash',
  90001: 'loyalty'
}
